import "core-js/modules/es.array.find.js";
import "core-js/modules/es.object.to-string.js";
import { templates } from "~/utils/valuationTransform/peValuationTemplates";
import { createValuationOutputFromHubData } from "~/utils/valuationTransform/index";
var useValuationUpdated = function useValuationUpdated(props) {
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var activeModel = computed(function () {
    // change this to active instead of first one
    // after we integrate this with the backend
    var currentCidModels = $store.state.valuationModel.allModels[props.cid];
    var currentModel = currentCidModels.find(function (model) {
      return model.modelId === props.modelId;
    });
    return currentModel;
  });
  var updatedMetrics = computed(function () {
    var _activeModel$value;
    if ((_activeModel$value = activeModel.value) !== null && _activeModel$value !== void 0 && (_activeModel$value = _activeModel$value.metadata) !== null && _activeModel$value !== void 0 && (_activeModel$value = _activeModel$value.hubData) !== null && _activeModel$value !== void 0 && _activeModel$value.valuationOutput) {
      var _activeModel$value2, _activeModel$value3;
      return createValuationOutputFromHubData({
        hubData: (_activeModel$value2 = activeModel.value) === null || _activeModel$value2 === void 0 || (_activeModel$value2 = _activeModel$value2.metadata) === null || _activeModel$value2 === void 0 ? void 0 : _activeModel$value2.hubData,
        latestStockPrice: (_activeModel$value3 = activeModel.value) === null || _activeModel$value3 === void 0 ? void 0 : _activeModel$value3.stockData,
        cases: templates.assumptionCases,
        yearsAhead: props.yearsAhead,
        modelStyle: props.modelStyle
      });
    }
    return {};
  });
  return {
    updatedMetrics: updatedMetrics
  };
};
export default useValuationUpdated;